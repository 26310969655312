/**
 * Transform Sanity Blocks to String
 * Source: https://github.com/sanity-io/sanity-recipes/blob/main/snippets/blocksToText.js
 */
const blocksToText = (blocks, opts = {}) => {
  const defaults = {nonTextBehavior: 'remove'}
  const options = Object.assign({}, defaults, opts)
  return blocks
    .map(block => {
      if (block._type !== 'block' || !block.children) {
        return options.nonTextBehavior === 'remove' ? '' : `[${block._type} block]`
      }

      return block.children.map(child => child.text).join('')
    })
    .join('\n\n')
}

module.exports = {
  blocksToText: blocksToText,
}