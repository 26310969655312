import React from 'react';
import { Link, navigate } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { blocksToText } from '../utils/sanity';
import removeMd from 'remove-markdown';

import './BlogItem.scss';

const createExcerpt = (string) => {
  // Strip HTML
  string = string.replace(/(<([^>]+)>)/gi, '');
  // Strip New Paragraph and replace with a space
  string = string.replace(/(\r\n|\n|\r)/gm, ' ');
  // Truncate to first 150 characters without breaking a word
  string = string.replace(/^(.{150}[^\s]*).*/, '$1');
  return string;
};

const createExcerptFromSanityBlocks = (text) => {
  return createExcerpt(blocksToText(text));
};

const createExcerptFromMarkdown = (markdown) => {
  return createExcerpt(removeMd(markdown));
};

const BlogItem = ({ post }) => {
  const {
    excerpt = null,
    title,
    date,
    slug,
    authors = [],
    featuredImage = null,
    _rawText,
    textMarkdown,
  } = post;

  let prefferedExcerpt = '';

  if (_rawText) prefferedExcerpt = createExcerptFromSanityBlocks(_rawText);
  if (textMarkdown) prefferedExcerpt = createExcerptFromMarkdown(textMarkdown);

  const excerptToUse = excerpt || createExcerptFromMarkdown(prefferedExcerpt);

  return (
    <div className="BlogItem">
      {featuredImage?.image && (
        <figure>
          <Link to={`/blog/${slug.current}`}>
            <Image
              {...featuredImage.image}
              width={450}
              sizes={`(max-width: 1000px) 100vw, 450px`}
              alt={featuredImage.altText || ''}
            />
          </Link>
        </figure>
      )}

      <div className="BlogItemContent">
        {title && (
          <h2>
            <Link to={`/blog/${slug.current}`} className="BlogItemTitle">
              {title}
            </Link>
          </h2>
        )}

        {excerptToUse && <p>{excerptToUse}...</p>}

        {date && (
          <div className="BlogItemFooter">
            {date && <div className="BlogItemFooterDate">{date}</div>}
            {authors &&
              authors.map((author) => {
                return (
                  <div
                    className="BlogItemFooterAuthor"
                    key={author.image.asset._id}
                  >
                    <Image
                      {...author.image}
                      width={24}
                      height={24}
                      alt={`Avatar of ${author.name}`}
                    />
                    {author.name}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogItem;
